import Phaser from 'phaser'

import StartScreen from './scenes/StartScreen'
import Kitchen from './scenes/Kitchen'

const config = {
    width: 960,
    height: 640,
    type: Phaser.AUTO,
    backgroundColor: 'black',
    physics: {
        default: 'arcade',
        arcade: {
          gravity: 0,
          enableBody: true,
        }
    }
}

const game = new Phaser.Game(config)

game.scene.add('startScreen', StartScreen)
game.scene.add('kitchen', Kitchen)


game.scene.start('startScreen')