import Phaser from "phaser"
import images from "../assets/*.png"

const gameState = {
    numCoordinates: {},
}

const cloudSpeed = 20;

export default class StartScreen extends Phaser.Scene {
    preload() {
        this.load.image('house', images.house)
        this.load.image('cloud', images.cloud)
    }

    create() {
        this.add.image(480, 320, 'house')
        
        gameState.cloud1 = this.physics.add.sprite(640, 50, 'cloud').setScale(0.5)
        gameState.cloud2 = this.physics.add.sprite(200, 50, 'cloud')
        gameState.cloud3 = this.physics.add.sprite(0, 50, 'cloud').setScale(0.25)
        gameState.cloud4 = this.physics.add.sprite(-100, 40, 'cloud').setScale(0.75)

        this.add.text(200, 50, 'Nona\'s House', {
            fontSize: 80,
            color: 'black'
        })
        this.add.text(300, 600, 'PRESS SPACE TO CONTINUE', {
            fontSize: 28,
            color: 'black'
        })

        this.input.keyboard.on('keydown', () => {
            this.scene.stop('startScreen');
            this.scene.start('kitchen');
          });
    }

    update() {
        const cloud1XCoord = gameState.cloud1.x;
        const cloud1YCoord = gameState.cloud1.y;
        const cloud2XCoord = gameState.cloud2.x;
        const cloud2YCoord = gameState.cloud2.y;
        const cloud3XCoord = gameState.cloud3.x;
        const cloud3YCoord = gameState.cloud3.y;
        const cloud4XCoord = gameState.cloud4.x;
        const cloud4YCoord = gameState.cloud4.y;

        if (cloud1XCoord >= 960 + 100) {
            resetCloud1()
        } else if (cloud2XCoord >= 960 + 100) {
            resetCloud2()
        } else if (cloud3XCoord >= 960 + 100) {
            resetCloud3()
        } else if (cloud4XCoord >= 960 + 100) {
            resetCloud4()
        }

        function moveCloud1() {
            gameState.cloud1.flipX = false;
            gameState.cloud1.setTexture('cloud');
            gameState.cloud1.setVelocityX(cloudSpeed);
            gameState.cloud1.setVelocityY(0);
        }
      
        function moveCloud2() {
            gameState.cloud2.flipX = true;
            gameState.cloud2.setTexture('cloud');
            gameState.cloud2.setVelocityX(cloudSpeed*1.25);
            gameState.cloud2.setVelocityY(0);
        }
      
        function moveCloud3() {
            gameState.cloud3.flipX = false;
            gameState.cloud3.setTexture('cloud');
            gameState.cloud3.setVelocityX(cloudSpeed*1.3);
            gameState.cloud3.setVelocityY(0);
        }

        function moveCloud4() {
            gameState.cloud4.flipX = false;
            gameState.cloud4.setTexture('cloud');
            gameState.cloud4.setVelocityX(cloudSpeed*0.9);
            gameState.cloud4.setVelocityY(0);
        }

        function resetCloud1() {
            gameState.cloud1.x = -100
        }

        function resetCloud2() {
            gameState.cloud2.x = -100
        }

        function resetCloud3() {
            gameState.cloud3.x = -100
        }

        function resetCloud4() {
            gameState.cloud4.x = -100
        }

        moveCloud1();
        moveCloud2();
        moveCloud3();
        moveCloud4();
    }
}